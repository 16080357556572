<template>
  <b-form @submit.prevent="emit_ok()">
    <b-form-group :label="$t('Category')">
      <b-form-select
        :options="options"
        v-model="options_for_report.category"
        text-field="_name"
        value-field="pk"
        required
      >
        <b-form-select-option :value="null"> ----</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group :label="$t('Line')">
      <b-form-select
        :options="lines"
        v-model="options_for_report.line"
        text-field="_name_with_dep"
        value-field="pk"
      >
        <b-form-select-option :value="null"> ----</b-form-select-option>
      </b-form-select>
    </b-form-group>
    <select-person-or-operator v-model="options_for_report.info" />
    <b-form-group :label="$t('Date')">
      <date-picker
        v-model="date"
        :request="to_blink"
        :allowed_periods="['day']"
      />
    </b-form-group>
    <b-form-row>
      <b-button variant="primary" type="submit">Ok</b-button>
    </b-form-row>
  </b-form>
</template>

<script>
import DatePicker from "./DateTimePicker.vue";
import SelectPersonOrOperator from "./SelectPersonOrOperator";
import { orderBy } from "lodash-es";

export default {
  name: "HCDReportForm",
  components: { SelectPersonOrOperator, DatePicker },
  mounted() {
    this.$store.dispatch("load_data", ["category", "additional_info"]);
  },
  data() {
    const restored = this.$store.getters.get_reports_data("hcd");
    let options_for_report = {
      category: null,
      start: {},
      line: null,
      info: null,
    };
    let date = {
      end: null,
      start: new Date(),
      period: "day",
    };
    if (restored) {
      options_for_report = restored.options;
      date = restored.date;
    }
    return {
      date,
      options_for_report,
    };
  },
  computed: {
    ok_is_active() {
      let ret = false;

      return ret;
    },
    to_blink() {
      let ret = {};
      let info_list = [];
      const { category, line, info } = this.options_for_report;
      if (category) {
        ret["category"] = category;
      }
      if (line) {
        info_list.push(line);
      }
      if (info) {
        info_list.push(info);
      }
      if (info_list.length) {
        ret["info"] = info_list;
      }
      return ret;
    },
    start() {
      return this.date.start;
    },
    options() {
      return this.$store.getters.category.filter((c) => !c.mark_as_deleted);
    },
    lines() {
      return orderBy(
        this.$store.getters.line.filter((o) => !o.deleted && !o.dep_deleted),
        ["name"]
      );
    },
  },
  methods: {
    emit_ok() {
      this.$store.commit("set_report_data", {
        key: "hcd",
        value: {
          options: this.options_for_report,
          date: this.date,
          type: this.info_type,
        },
      });
      const obj = {
        ...this.options_for_report,
        ...this.date,
      };
      this.$emit("ok", obj);
    },
  },
};
</script>

<style>
</style>
