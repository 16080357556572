<template>
  <b-form @submit.prevent="send()">
    <h2>
      {{$t('change_password')}}
    </h2>
    <b-form-group :label="$t('old_password')" label-cols-sm="12" label-cols-md="3"
                  :state="fields_state.old_password"
                  :invalid-feedback="fields_error_messages.old_password"
    >
      <b-input v-model="old_password" type="password" :state="fields_state.old_password"/>
    </b-form-group>

    <b-form-group :label="$t('new_password')" type="password" label-cols-sm="12" label-cols-md="3"
                  :invalid-feedback="fields_error_messages.new_password"
    >
      <b-input v-model="new_password" type="password" :state="fields_state.new_password"/>
    </b-form-group>

    <!--    <b-form-group :label="$t('new_password2')" type="password" label-cols-sm="12" label-cols-md="3">-->
    <!--      <b-input v-model="new_password2"/>-->
    <!--    </b-form-group>-->

    <b-form-group>
      <b-button variant="primary" type="submit">{{$t('change_password')}}</b-button>
      <b-button variant="outline-primary" type="reset" class="m-2">{{$t('reset')}}</b-button>
      <b-button variant="outline-primary" :to="{name: 'Home'}" class="m-2">{{$t('Back')}}</b-button>
    </b-form-group>
  </b-form>
</template>

<script>
  export default {
    name: "SetPasswordForm",
    data() {
      return {
        old_password: '',
        new_password: '',
        new_password2: '',
        errors: {}
      }
    },
    computed: {
      fields_state() {
        let ret = {
          old_password: null,
          new_password: null,
          new_password2: null,
        }
        if (this.errors.current_password) {
          ret.old_password = false
        }
        if (this.errors.new_password) {
          ret.new_password = false
        }
        return ret
      },
      fields_error_messages() {
        let ret = {
          old_password: null,
          new_password: null,
          new_password2: null,
        }
        if (this.fields_state.old_password === false) {
          ret.old_password = this.errors.current_password.join(', ')
        }
        if (this.fields_state.new_password === false) {
          ret.new_password = this.errors.new_password.join(', ')
        }
        return ret
      },
      send_data() {
        return {
          current_password: this.old_password,
          new_password: this.new_password,
          // re_new_password: this.new_password2
        }
      },
    },
    methods: {
      send() {
        this.$store.dispatch('change_password', this.send_data).then(r => {
          if (r.ok) {
            this.errors = {};
            this.$router.push({name: 'Home'})
          } else if (r.status == 400) {
            r.json().then(
              data => {
                this.errors = data
              }
            )
          }

        })
      }
    }
  }
</script>

<style scoped>

</style>
