<template>
  <div v-frag>
    <b-form-group :label="$t('Person')">
      <b-input-group>
        <b-form-select
            :options="persons"
            :value="person"
            @change="person = $event"
            text-field="_name"
            value-field="pk"
        >
        </b-form-select>
        <b-input-group-append v-if="person">
          <b-button variant="outline-danger" @click="clear()">X</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group :label="$t('Operator')">
      <b-input-group>

        <b-form-select
            :options="operators"
            :value="operator"
            @change="operator = $event"
            text-field="_name"
            value-field="pk"
        >

        </b-form-select>
        <b-input-group-append v-if="operator">
          <b-button variant="outline-danger" @click="clear()">
            X
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
  //import {Fragment} from 'vue-fragment'
  import {orderBy} from 'lodash-es'
  import frag from 'vue-frag'

  export default {
    name: "SelectPersonOrOperator",
    props: {
      value: {
        default: null,
        required: true
      },
      options: {
        type: Array,
        default: null,
        required: false
      },
      show_deleted: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    directives: {frag},
    //components: {Fragment},
    created() {
      const restore_type = (id) => {
        const obj = this.$store.getters.additional_info.find(o => o.pk == id)
        return obj ? obj.type : 'person'
      }
      this.info_type = restore_type(this.val)
    },
    data() {
      return {
        info_type: 'person'
      }
    },
    computed: {
      val: {
        get() {
          return this.value
        },
        set(to) {
          this.$emit('input', to)
        }
      },
      operators() {
        let items = this.$store.getters.operator;
        if (this.options != null) {
          const opt = this.options.map(p => parseInt(p.pk));
          items = items.filter(i => opt.indexOf(parseInt(i.pk)) > -1)
        }
        if (!this.show_deleted) {
          items = items.filter(o => !o.deleted)
        }
        return orderBy(items, ['name']);
      },
      persons() {
        let items = this.$store.getters.person;
        if (this.options != null) {
          const opt = this.options.map(p => parseInt(p.pk));
          items = items.filter(i => opt.indexOf(parseInt(i.pk)) > -1)
        }
        if (!this.show_deleted) {
          items = items.filter(o => !o.deleted)
        }
        return orderBy(items, ['name']).filter(o => !o.deleted);
      },
      person: {
        get() {
          return this.info_type == "person" ? this.val : null;
        },
        set(to) {
          this.val = to;
          this.info_type = "person";
          this.$forceUpdate();
        },
      },
      operator: {
        get() {
          return this.info_type == "operator"
            ? this.val
            : null;
        },
        set(to) {
          this.val = to;
          this.info_type = "operator";
          this.$forceUpdate();
        },
      },
    },
    methods: {
      clear() {
        this.val = null
      },
      restore_type(id) {
        const obj = this.$store.getters.additional_info.find(o => o.pk == id)
        return obj ? obj.type : 'person'
      }
    }
  }
</script>

<style scoped>

</style>
