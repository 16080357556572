<template>
  <b-form @submit.prevent="emit_ok">
    <b-form-group :label="$t('Category')">
      <b-select
        multiple
        :options="categorys"
        text-field="_name"
        value-field="pk"
        v-model="category"
      ></b-select>
    </b-form-group>
    <b-form-group :label="$t('Departament')">
      <b-select
        multiple
        v-model="departament"
        :options="departaments"
        text-field="_name"
        value-field="pk"
      />
    </b-form-group>
    <b-form-group :label="$t('Cells')">
      <b-select
        multiple
        v-model="cell"
        :options="cells"
        text-field="_name_with_dep"
        value-field="pk"
      />
    </b-form-group>

    <b-form-group :label="$t('Lines')">
      <b-select
        multiple
        v-model="line"
        :options="lines"
        text-field="_name_with_dep"
        value-field="pk"
      />
    </b-form-group>

    <b-form-group :label="$t('Operators')">
      <b-select
        multiple
        v-model="operator"
        :options="operators"
        text-field="_name"
        value-field="pk"
      />
    </b-form-group>

    <b-form-group :label="$t('Persons')">
      <b-select
        multiple
        v-model="person"
        :options="persons"
        text-field="_name"
        value-field="pk"
      />
    </b-form-group>
    <b-form-group :label="$t('Group by')">
      <b-select :options="group_options" v-model="group_by" />
    </b-form-group>
    <b-form-group :label="$t('Date')">
      <date-picker v-model="date" :request="to_send" />
    </b-form-group>

    <b-form-row>
      <b-button type="submit" variant="primary" @click="emit_ok()">Ok</b-button>
    </b-form-row>
  </b-form>
</template>

<script>
import DatePicker from "./DateTimePicker";
import { merge } from "lodash-es";

export default {
  name: "GraphForm",
  components: { DatePicker },
  mounted() {
    this.$store.dispatch("load_data", [
      "category",
      "additional_info",
      "departament",
    ]);
  },
  data() {
    return {
      date: { period: "week", start: new Date(), end: null },
      category: [],
      departament: [],
      cell: [],
      person: [],
      operator: [],
      line: [],
      group_by: "day",
    };
  },
  methods: {
    emit_ok() {
      const obj = merge({}, this.to_send);
      // this.$store.commit('set_report_data', {
      //   key: 'base',
      //   value: {date: this.selected_date, selected: this.selected}
      // });
      this.$emit("ok", obj);
    },
  },
  computed: {
    group_options() {
      return [
        { text: this.$t("None"), value: "none" },
        { text: this.$t("Day"), value: "day" },
        { text: this.$t("Week"), value: "week" },
        { text: this.$t("Month"), value: "month" },
        { text: this.$t("Quarter"), value: "quarter" },
      ];
    },
    to_send() {
      return {
        category: this.category,
        departament: this.departament,
        group_by: this.group_by,
        info: [...this.person, ...this.line, ...this.operator, ...this.cell],
        ...this.date,
      };
    },
    categorys() {
      return this.$store.getters.category.filter((c) => !c.mark_as_deleted);
    },
    departaments() {
      return this.$store.getters.departament.filter((d) => !d.deleted);
    },
    persons() {
      return this.$store.getters.person.filter((o) => !o.deleted);
    },
    operators() {
      return this.$store.getters.operator.filter((o) => !o.deleted);
    },
    lines() {
      return this.$store.getters.line.filter(
        (o) => !o.deleted && !o.dep_deleted
      );
    },
    cells() {
      return this.$store.getters.cell.filter(
        (o) => !o.deleted && !o.dep_deleted
      );
    },
  },
};
</script>

<style scoped>
</style>
