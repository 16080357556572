<template>
  <b-form class='wrf' @submit.prevent="emit_ok()">
    <b-form-group :label="$t('Health Check')">
      <b-form-select
          :options="category_options"
          v-model="selected"
          text-field="_name" value-field="pk"
      />
    </b-form-group>
    <b-form-group :label="$t('Line')">
      <b-form-select :options="line_options" v-model="selected_line"
                     text-field="_name_with_dep" value-field="pk" multiple
                     :select-size="7"
      />
    </b-form-group>


    <b-form-group :label="$t('Date')">
      <date-picker v-model="selected_date" :request="data_to_select"/>
    </b-form-group>
    <b-form-row>
      <b-button variant="primary" type="submit">Ok</b-button>
    </b-form-row>
  </b-form>
</template>

<script>
import DatePicker from "./DateTimePicker";
import {merge, orderBy} from 'lodash-es'


export default {
  name: "WeeklyReportForm",
  components: {DatePicker},
  mounted() {
    this.$store.dispatch('load_data', ['category', 'additional_info', 'departament']);
    const restored = this.$store.getters.get_reports_data('weekly')
    if (restored) {
      this.selected = restored.category
      this.selected_line = restored.lines
      this.selected_date = restored.date
    }
  },
  data() {
    return {
      selected: null,
      selected_date: {period: 'week', start: new Date()},
      selected_line: []
    }
  },
  computed: {
    data_to_select() {
      let ret = {}
      if (this.selected_line.length) {
        ret['info'] = this.selected_line
      }
      if (this.selected) {
        ret['category'] = this.selected
      }
      return ret
    },
    category_options() {
      return [
        {_name: this.$t('Select HC'), id: null},
        ...this.$store.getters['category'].filter(c => !c.deleted)
      ]
    },
    line_options() {
      return orderBy(
          this.$store.getters.line.filter(
              l => !l.deleted && !l.dep_deleted
          ), ['name'])
    }
  },
  methods: {
    emit_ok() {
      this.$store.commit('set_report_data', {
        key: 'weekly', value: {
          category: this.selected,
          lines: this.selected_line,
          date: this.selected_date
        }
      })
      this.$emit('ok', merge(
          this.selected_date, {
            lines: this.selected_line || [],
            category: this.selected
          }))
    },
  }
}
</script>

<style lang="scss">
.wrf {

}
</style>
