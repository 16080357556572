<template>
  <b-container class="w_100">
    <b-row>
      <b-col>
        <SetPasswordForm/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import SetPasswordForm from "../components/SetPasswordForm";

  export default {
    name: "ChangePasswordView",
    components: {SetPasswordForm}
  }
</script>

<style scoped>

</style>
