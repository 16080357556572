<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Settings") }}
          <b-link :to="{ name: 'Home' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
      </b-col>
    </b-row>
    <b-row class="actions">
      <b-col>
        <b-button
          class="action"
          variant="primary"
          :to="{ name: 'Info', params: { type: 'person' } }"
        >
          <b-icon-person />
          <br />
          {{ $t("Persons") }}
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="action"
          variant="primary"
          :to="{ name: 'Info', params: { type: 'operator' } }"
        >
          <b-icon-check />
          <br />
          {{ $t("Operators") }}
        </b-button>
      </b-col>
      <b-col>
        <b-button class="action" variant="warning" :to="{ name: 'Lines' }">
          <b-icon-building />
          <br />
          {{ $t("Lines") }}
        </b-button>
      </b-col>
      <b-col>
        <b-button class="action" variant="warning" :to="{ name: 'Cells' }">
          <b-icon-grid3x2 />
          <br />
          {{ $t("Departments & Cells") }}
        </b-button>
      </b-col>
      <b-col>
        <b-button class="action" variant="success" :to="{ name: 'Devices' }">
          <b-icon-phone />
          <br />
          {{ $t("Devices") }}
        </b-button>
      </b-col>

      <b-col>
        <b-button
          variant="outline-info"
          class="action"
          :to="{ name: 'Category' }"
        >
          <b-icon-question-circle-fill />
          <br />
          {{ $t("Categories") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SelectOption",
  computed: {
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Settings"), active: true },
      ];
    },
  },
};
</script>
