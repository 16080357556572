<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Category Perfomance") }}
          <b-link :to="{ name: 'Reports' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <h2>
          {{ repr }}
          <b-link @click="show_modal = true">
            <b-icon-gear />
          </b-link>
          <a :href="build_url(true, true)">
            <b-icon-download />
          </a>
        </h2>
        <b-modal v-model="show_modal" hide-footer hide-header>
          <cat-perf-form @ok="go_to" />
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <b-table-simple v-if="!loading && !is_empty">
            <b-tr>
              <b-th>{{ $t("Вопрос") }}</b-th>
              <b-th>{{ $t("Всего ответов") }}</b-th>
              <b-th>{{ $t("Всего NOK, шт") }}</b-th>
              <b-th>{{ $t("Всего NOK, %") }}</b-th>
              <b-th>{{ $t("Всего OK, шт") }}</b-th>
              <b-th>{{ $t("Всего OK, %") }}</b-th>
            </b-tr>
            <b-tr v-for="(dt, id) in data.data" :key="id">
              <b-th>{{ translate_obj(data.questions[id]) }}</b-th>
              <b-td>{{ dt.total }}</b-td>
              <b-td>{{ dt.nok }}</b-td>
              <b-td>{{ dt.nok_p | fl }}</b-td>
              <b-td>{{ dt.ok }}</b-td>
              <b-td>{{ dt.ok_p | fl }}</b-td>
            </b-tr>
          </b-table-simple>
          <h2 v-if="!loading && is_empty">{{ $t("Nothing found") }}</h2>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { merge } from "lodash-es";
import CatPerfForm from "../components/CatPerfForm.vue";
import { dict_to_search } from "../shared";
import { SERVER } from "../constants";
// import {orderBy, merge} from 'lodash-es'
// import {dict_to_search, to_query, date_format} from "../shared";

// import {SERVER} from "../constants";

export default {
  name: "CatPerfReport",
  components: { CatPerfForm },
  filters: {
    fl: (val) => val.toFixed(2),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("load_data", [
      "category",
      "departament",
      "additional_info",
    ]);
    await this.load_data();
    this.$watch(() => this.$route, this.load_data);
  },
  data() {
    return {
      show_modal: false,
      data: {},
      loading: false,
    };
  },
  methods: {
    async load_data() {
      this.loading = true;
      const url = this.build_url();
      let data = await this.$store.dispatch("get", { url, raw: true });
      this.data = await data.json();
      this.loading = false;
    },
    build_url(xls = false, with_server = false) {
      let set_data = merge({}, this.$route.query);
      if (xls === true) {
        set_data["frmt"] = "xlsx";
      }
      const query_str = dict_to_search(set_data);
      let url = `api/reports/catperf/?${query_str}`;
      if (with_server) {
        url = `${SERVER}/${url}`;
      }
      return url;
    },
    go_to(data) {
      let url = this.$route.path;
      let query_str = dict_to_search(data);
      this.$router.push({
        path: `${url}?${query_str}`,
      });
      this.show_modal = false;
      //return "";
    },
    translate_obj(obj) {
      const lang = this.$store.getters.lang;
      return obj[lang] || obj["en"];
    },
  },

  computed: {
    is_empty() {
      return !Object.keys(this.data?.data ?? {}).length;
    },
    repr() {
      let ret = `${this.current_info.category?.name}, ${this.current_info.departament?.name}`;
      if (this.current_info?.line) {
        ret = `${ret} - ${this.current_info.line?.name}`;
      }
      return ret;
    },
    current_info() {
      return {
        line: this.$store.getters.line.find(
          (el) => el.pk == this.$route.query.line
        ),
        category: this.$store.getters.category.find(
          (el) => el.pk == this.$route.query.category
        ),
        departament: this.$store.getters.departament.find(
          (el) => el.pk == this.$route.query.departament
        ),
      };
    },
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), to: { name: "Reports" } },
        { text: this.$t("Category Perfomance"), active: true },
      ];
    },
  },
};
</script>