<template>
  <b-input-group class="date_select">
    <b-dropdown
      id="select_date_dropdown"
      variant="outline-primary"
      block
      split
      class="w-100"
      ref="dropdown"
      split-class="date-btn"
      no-caret
      :text="text_repr"
      @click="show_dropdown()"
    >
      <b-dropdown-form class="subform">
        <b-calendar
          v-model="start"
          v-bind="picker_config"
          ref="calendar"
          :date-info-fn="dateClass"
        />
        <b-calendar
          v-if="period === 'period'"
          v-model="end"
          v-bind="{ ...picker_config, ...mm_2 }"
          ref="calendar2"
          :date-info-fn="dateClass2"
        />
        <b-list-group>
          <b-list-group-item
            v-for="(opt, i) in options"
            :key="i"
            :class="{ secondary: !!opt.type[1] }"
            :active="opt.is_active"
            @click="set_period(...opt.type)"
          >
            {{ opt.text }}
          </b-list-group-item>
        </b-list-group>
      </b-dropdown-form>
      <b-dropdown-form>
        <b-button variant="primary" @click="$refs.dropdown.hide(true)"
          >Ok</b-button
        >
      </b-dropdown-form>
    </b-dropdown>
  </b-input-group>
</template>

<script>
// формат вывода {period: [day, week, month, period], start: date, ?end: date]}
import {
  format,
  getISOWeek,
  startOfISOWeek,
  startOfMonth,
  sub,
} from "date-fns";
import { dict_to_search } from "../shared";

const date_format = (value) =>
  value ? format(new Date(value), "yyyy-LL-dd") : "Set value";

export default {
  name: "DatePicker",
  filters: {
    date_format,
  },
  props: {
    max: {
      type: [Date, null],
      default: () => new Date(),
      required: false,
    },
    value: Object,
    request: {
      type: Object,
      default: null,
      required: false,
    },
    allowed_periods: {
      type: Array,
      default: () => ["day", "week", "month", "period"],
      required: false,
    },
    default: {
      required: false,
      type: Object,
      default: () => ({
        period: "week",
        start: sub(new Date(), { days: 7 }),
      }),
    },
    request_url: {
      type: String,
      default: "api/reports/dates",
      required: false,
    },
  },
  mounted() {
    this.request_data();
    if (this.end) {
      this.request_data2();
    }
    this.$watch(() => JSON.stringify(this.request), this.request_data);
    this.$watch(() => this.current_date, this.request_data);
    this.$watch(
      () => JSON.stringify([this.current_date2, this.period, this.end]),
      this.request_data2
    );
  },
  data() {
    return {
      show_dt: false,
      to_light: [],
      to_light2: [],
    };
  },
  computed: {
    mm() {
      let ret = {};
      return ret;
    },
    mm_2() {
      let ret = {};
      if (this.start) {
        ret["min"] = this.start;
      }
      return ret;
    },
    current_date() {
      let ret = null;
      if (this.$refs.calendar) {
        ret = this.$refs.calendar.activeDate;
      }
      return ret;
    },
    current_date2() {
      let ret = null;
      //if (this.$refs.calendar2) {
      ret = this.$refs?.calendar2?.activeDate || null;
      //}
      return ret;
    },
    text_repr() {
      let ret = "unknown";
      if (this.period === "day") {
        return date_format(this.start);
      } else if (this.period === "week") {
        return `${this.$t("Week")} ${getISOWeek(this.start)}`;
      } else if (this.period === "month") {
        return `${this.$t("Month")} ${this.start.getMonth() + 1}`;
      } else if (this.period === "period") {
        return `${date_format(this.start)} - ${date_format(
          this.end || new Date()
        )}`;
      }
      return ret;
    },
    start: {
      get() {
        return new Date(this.value.start || this.default.start);
      },
      set(to) {
        const period = this.period;
        const start = to;
        const end = this.end;
        if (this.period != "period") {
          this.$refs.dropdown.hide(true);
        }
        return this.$emit("input", { period, start, end });
      },
    },
    end: {
      get() {
        let ret = this.value.end || this.default.end || null;
        return ret;
      },
      set(to) {
        console.log("hc:end set to", to);
        const period = this.period;
        const start = this.start;
        const end = to;
        if (this.period == "period") {
          this.$refs.dropdown.hide(true);
        }
        return this.$emit("input", { period, start, end });
      },
    },
    period: {
      get() {
        return this.value.period || this.default.period || "day";
      },
      set(to) {
        const period = to;
        const start = this.start;
        const end = this.end;
        return this.$emit("input", { period, start, end });
      },
    },
    options() {
      let ret = [];
      if (this.allowed_periods.indexOf("day") > -1) {
        ret = ret.concat([{ type: ["day", null], text: this.$t("Day") }]);
      }
      if (this.allowed_periods.indexOf("week") > -1) {
        ret = ret.concat([
          { type: ["week", null], text: this.$t("Week") },
          {
            type: ["week", startOfISOWeek(new Date())],
            text: this.$t("Current Week"),
          },
          {
            type: ["week", startOfISOWeek(sub(new Date(), { days: 7 }))],
            text: this.$t("Last Week"),
          },
        ]);
      }
      if (this.allowed_periods.indexOf("month") > -1) {
        ret = ret.concat([
          { type: ["month", null], text: this.$t("Month") },
          {
            type: ["month", startOfMonth(new Date())],
            text: this.$t("Current Month"),
          },
          {
            type: ["month", startOfMonth(sub(new Date(), { months: 1 }))],
            text: this.$t("Last Month"),
          },
        ]);
      }
      if (this.allowed_periods.indexOf("period") > -1) {
        ret = ret.concat([{ type: ["period", null], text: this.$t("Period") }]);
      }

      for (const i in ret) {
        ret[i].is_active =
          this.period === ret[i].type[0] && ret[i].type[1] === null;
      }
      return ret;
    },
    picker_config() {
      let ret = {
        dateFormatOptions: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
        valueAsDate: true,
        startWeekday: 1,
      };
      if (this.max) {
        ret["max"] = this.max;
      }
      return ret;
    },
  },
  methods: {
    dateClass(ymd) {
      const cls = "table-success";
      if (this.to_light.indexOf(ymd) >= 0) {
        return cls;
      }
      return "";
    },
    dateClass2(ymd) {
      const cls = "table-success";
      if (this.to_light2.indexOf(ymd) >= 0) {
        return cls;
      }
      return "";
    },
    request_data() {
      if (this.request) {
        const data_to_send = {
          ...this.request,
          date: this.current_date,
        };
        const query = dict_to_search(data_to_send);
        this.$store
          .dispatch("get", { url: `${this.request_url}/?${query}` })
          .then((r) => (this.to_light = r));
      }
    },
    request_data2() {
      if (this.request) {
        const data_to_send = {
          ...this.request,
          date: this.current_date2,
        };
        const query = dict_to_search(data_to_send);
        this.$store
          .dispatch("get", { url: `${this.request_url}/?${query}` })
          .then((r) => (this.to_light2 = r));
      }
    },
    show_dropdown() {
      this.show_dt = !this.show_dt;
      this.$refs.dropdown.show(this.show_dt);
    },
    set_period(type, start = null) {
      this.period = type;
      if (start) {
        this.$nextTick(() => {
          this.start = start;
          this.$refs.dropdown.hide(true);
        });
        // this.start = start
      }
    },
  },
};
</script>

<style lang="scss">
.date_select {
  .date-btn {
    text-align: left;
  }

  .subform {
    .b-dropdown-form {
      display: flex;
      flex-direction: row;

      .list-group {
        margin-left: 10px;
      }

      .list-group-item {
        $padding-size: 10px;
        white-space: nowrap;
        padding-top: $padding-size;
        padding-bottom: $padding-size;

        &.secondary {
          padding-left: 1.5em;
          color: var(--secondary);

          &.active {
            color: white;
          }
        }
      }
    }
  }
}
</style>
