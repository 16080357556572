<template>
  <b-form>
    <select-person-or-operator v-model="to_send.info"/>
    <b-form-group :label="$t('Date')">
      <date-picker v-model="date" :request="to_link"/>
    </b-form-group>
    <b-form-group>
      <b-button variant="primary" @click="emit_ok()">Ok</b-button>
    </b-form-group>
  </b-form>
</template>

<script>
  import SelectPersonOrOperator from "./SelectPersonOrOperator";
  import DatePicker from "./DateTimePicker";
  import {pickBy} from 'lodash-es'

  export default {
    name: "ReportByPersonForm",
    components: {DatePicker, SelectPersonOrOperator},
    mounted() {
      this.$store.dispatch('load_data', ['additional_info'])

    },
    data() {
      const restored = this.$store.getters.get_reports_data('person')
      let date = {period: 'day', start: new Date(), end: null}
      let to_send = {
        info: null,
      }
      if (restored) {
        date = restored.date
        to_send = restored.to_send
        if (date.start) {
          date.start = new Date(date.start)
        }
        if (date.end) {
          date.end = new Date(date.end)
        }
      }
      return {
        date,
        to_send
      }
    },
    computed: {
      to_link() {
        let ret = pickBy(this.to_send, val => !!val);
        return ret
      }
    },
    methods: {
      emit_ok() {
        let ret = {...this.date, ...this.to_send}
        this.$store.commit('set_report_data', {
          key: 'person', value: {
            date: this.date,
            to_send: this.to_send
          }
        })
        this.$emit('ok', ret)
      }
    }
  }
</script>

<style scoped>

</style>
