import Vue from 'vue'
import Vuex from 'vuex'
import { merge, orderBy, pick } from 'lodash-es'
import { CSRF_TOKEN_HEADER_KEY, LOGOUT_TIMEOUT, SERVER, TIMEOUT_DEBOUNCE } from "../constants";
import { getCookie, getLCName, getName } from '../shared'

Vue.use(Vuex);
const default_config = {
  headers: {
    'Content-Type': 'application/json'
  },
  mode: 'cors'
}

const abs_keys = ['created', 'updated', 'deleted'];

const restore_synced_abstract_data = (value) => {
  const ret = abs_keys.reduce(
    (r, k) => {
      return merge(r, { [k]: value[k] ? new Date(value[k]) : null })
    },
    { _name: getName(value) }
  );
  return ret
}

const types_to_load = {
  category: 'api/v2/category/',
  departament: 'api/reports/objects/departament/',
  factory: 'api/reports/objects/factory/',
  additional_info: 'api/reports/objects/info/',
  device: 'api/v2/device/',
  question: 'api/v2/question/'
}

const _storage = process.env.NODE_ENV == 'development' ? localStorage : sessionStorage;

export default new Vuex.Store({
  state() {
    let reports_data = sessionStorage.getItem('reports_data', null);
    if (reports_data) {
      reports_data = JSON.parse(reports_data)
    } else {
      reports_data = {}
    }

    return {
      wait_resp: false,
      user_info: {},
      category: [],
      departament: [],
      lang: _storage.getItem('lang', 'en'),
      factory: [],
      additional_info: [],
      device: [],
      question: [],
      token: sessionStorage.getItem('token', ''),
      has_auth: false,
      reports_data: reports_data
    }
  },
  mutations: {
    toggle_wait: (state, value = null) => {
      if (value === null) {
        state.wait_resp = !state.wait_resp
      } else if (value === true || value === false) {
        state.wait_resp = value
      }
    },
    update_lang: (state, value) => {
      state.lang = value
      _storage.setItem('lang', value)
    },
    update_user_info: (state, info = {}) => state.user_info = info,
    set_auth_state: (state, value) => {
      state.has_auth = value
    },
    set_report_data: (state, { key, value }) => {
      state['reports_data'][key] = value
      _storage.setItem('reports_data', JSON.stringify(state['reports_data']))
    },
    load: (state, { type: type = 'category', items: items = [] }) => {
      state[type] = items
    },
    add_item: (state, { type, item }) => {
      state[type] = [item, ...state[type]]
    },
    update_item: (state, { type, item, id }) => {
      state[type] = [item, ...state[type].filter(d => d.uuid !== id)]
    },
    set_token: (state, to) => {
      state.token = to
      state.has_auth = true
      _storage.setItem('token', to)
    },
    clear_token: (state) => {
      state.token = null;
      state.has_auth = false
      _storage.removeItem('token');
      _storage.removeItem('reports_data');
    }
  },
  actions: {
    check_login({ dispatch, commit }) {
      let ret = false
      return dispatch('get', {
        url: 'auth/users/me/',
        raw: true
      }).then(
        r => {
          if (r.status == 200) {
            ret = true
          }
          commit('set_auth_state', ret)
          return r.json()
        }
      ).then(
        (data) => {
          if (ret) {
            commit('update_user_info', data)
          }
          return ret
        }
      )
    },
    get: ({ getters }, { url, config: config = {}, use_token: use_token = true, raw: raw = false }) => {
      const real_url = `${SERVER}/${url}`
      let add_config = {
        method: 'GET'
      }
      if (use_token) {
        add_config['headers'] = getters.get_auth_headers
      }
      const real_config = merge({}, default_config, add_config, config)
      return fetch(real_url, real_config).then(resp => {
        if (raw) return resp
        else if (resp.ok) {
          return resp.json()
        } else return []
      })
    },
    post: ({ getters }, { url, config: config = {}, data: data = {}, use_token: use_token = true }) => {
      let add_config = {
        body: JSON.stringify(data),
        method: 'POST'
      }
      if (use_token) {
        add_config['headers'] = getters.get_auth_headers
      }
      const real_url = `${SERVER}/${url}`
      const real_config = merge({}, default_config, add_config, config)
      return fetch(real_url, real_config)
    },
    put: ({ getters }, { url, config: config = {}, data: data = {} }) => {
      let add_config = {
        body: JSON.stringify(data),
        method: 'PATCH',
        headers: getters.get_auth_headers
      }
      const real_url = `${SERVER}/${url}`
      const real_config = merge({}, default_config, add_config, config)
      return fetch(real_url, real_config)
    },
    update_item: ({ dispatch, commit }, { type, id, data }) => {
      const real_url = `${types_to_load[type]}${id}/`
      return dispatch('put', {
        url: real_url,
        data: data
      }).then(
        resp => {
          if (resp.ok) {
            return resp.json()
          }
          return null
        }
      ).then(data => {
        if (data) {
          commit('update_item', { type, id, item: data })
        }
      })
    },
    add_question: ({ dispatch, commit }, [uuid, data]) => {
      return dispatch('post', {
        url: `${types_to_load.category}${uuid}/add_question/`,
        data
      }).then(
        resp => {
          if (resp.ok) {
            return resp.json()
          }
          return new Promise(resolve => resolve(null))
        }
      ).then(
        d => {
          if (d) {
            commit('update_item', { type: 'category', id: uuid, item: d })
          }
          return new Promise(resolve => resolve(null))
        }
      )
    },
    add_item: ({ dispatch, commit }, { type, data }) => {
      return dispatch('post', {
        url: types_to_load[type],
        data
      }).then(
        r => {
          if (r.ok) {
            return r.json()
          }
          return null
        }
      ).then(
        data => {
          if (data) {
            console.log('hc:add_item', data)
            commit('add_item', { type, item: data })
            return data
          }
          return null
        }
      )
    },
    login: ({ dispatch, commit }, { username, password }) => {
      return dispatch('post', { url: 'auth/token/login/', data: { username, password }, use_token: false }).then(
        resp => {
          return resp.json()
        }
      ).then(
        (data) => {
          if (data.auth_token) {
            commit('set_token', data.auth_token)
            return { status: true }
          }
          return { status: false, data }
        }
      )
    },
    logout: ({ dispatch, commit }) => {
      return dispatch('post', { url: 'auth/token/logout/', data: {}, use_token: true }).then(() => {
        commit('clear_token');
      })
    },
    change_password: ({ dispatch }, data) => {
      return dispatch('post', { url: 'auth/users/set_password/', data, use_token: true })
    },
    load_data({ commit, dispatch }, _types_to_load = null) {
      const keys = _types_to_load === null ? ['category', 'departament', 'factory', 'additional_info', 'device'] : _types_to_load
      const urls = pick(types_to_load, keys);
      return Promise.all(
        Object.entries(urls).map(
          ([t, url]) => dispatch('get', { url }).then(d => commit('load', { type: t, items: d }))
        )
      )
    }
  },
  getters: {
    user_info: state => state.user_info,
    use_background_check: (state, getters) => {
      return getters.user_info.use_background_check ?? true
    },
    _timeout_debounce: (state, getters) => {
      return getters.user_info.timeout_debounce ?? TIMEOUT_DEBOUNCE
    },
    _timeout_timeout: (state, getters) => {
      return (getters.user_info.timeout ?? LOGOUT_TIMEOUT) * 1000
    },
    get_auth_headers: (state, getters) => {
      let ret = {}
      if (getters.token) {
        ret['Authorization'] = `Token ${getters.token}`
      } else {
        const token = getCookie()
        if (token) {
          ret[CSRF_TOKEN_HEADER_KEY] = token
        }
      }
      return ret
    },
    auth_type: (state, getters) => {
      let ret = null
      if (getters.has_auth) {
        if (state.token) {
          ret = 'token'
        } else {
          ret = 'session'
        }
      }
      return ret
    },
    lang: state => state.lang,
    translate_options: (state) => (obj, keys = []) => {
      let ret = {};
      const lang = state.lang || 'en';
      for (const key of keys) {
        const val = obj[key] || {};
        ret[`_t_${key}`] = val[lang] || '----s';
      }
      return ret;
    },

    get_reports_data: state => key => state.reports_data[key] || null,
    token: state => state.token,
    has_auth: state => state.has_auth,
    category: state => orderBy(state.category.map(c => merge({}, c, { id: c.pk }, restore_synced_abstract_data(c))), 'name'),
    question: (state, getters) => state.question.map(q => merge({}, q, restore_synced_abstract_data(q), getters.translate_options(q, ['name', 'description']))),
    factory: state => orderBy(state.factory || [], 'name'),
    device: state => state.device,
    additional_info: (state, getters) => orderBy(
      state.additional_info.map(d => merge({}, d, restore_synced_abstract_data(d))).map(
        d => {
          let ret = { ...d };
          if (ret.type == 'line' || ret.type == 'cell') {
            ret['_name_with_dep'] = getLCName(getters, d);
            if (ret.departament_uuid) {
              ret['dep_deleted'] = getters.departament_as_uuid[ret.departament_uuid]?.deleted || null;
            }
          }
          return ret
        }
      ),
      'name'
    ),
    departament: state => orderBy((state.departament || []).map(d => merge({}, d, restore_synced_abstract_data(d))), 'name'),
    person: (state, getters) => getters.additional_info.filter(s => s.type === 'person'),
    operator: (state, getters) => getters.additional_info.filter(s => s.type === 'operator'),
    cell: (state, getters) => getters.additional_info.filter(s => s.type === 'cell'),
    line: (state, getters) => getters.additional_info.filter(s => s.type === 'line'),
    ...['category', 'question', 'factory', 'additional_info', 'departament', 'person', 'operator', 'cell', 'line'].reduce(
      (r, name) => ({
        ...r,
        [`${name}_as_pk`]: (state, getters) => getters[name].reduce((r1, o) => ({ ...r1, [o.pk]: o }), {}),
        [`${name}_as_uuid`]: (state, getters) => getters[name].reduce((r1, o) => ({ ...r1, [o.uuid]: o }), {})
      }), {}
    )
  },
  modules: {}
})
