<template>
  <b-form @submit.prevent="emit_ok()">
    <b-form-group :label="$t('Departament')">
      <b-form-select :options="options" v-model="selected" required value-field="value"
                     :placeholder="$t('Select Departament')"/>
    </b-form-group>
    <b-form-group :label="$t('Date')">
      <date-picker v-model="selected_date" :request="data_to_req" :allowed_periods="['week', 'month']"/>
    </b-form-group>
    <b-form-row>
      <b-button variant="primary" type="submit">Ok</b-button>
    </b-form-row>
  </b-form>
</template>

<script>
  import {groupBy, merge} from 'lodash-es';
  import DatePicker from "./DateTimePicker";

  export default {
    name: "ReportForm",
    components: {DatePicker},
    mounted() {
      // restore
      this.$store.dispatch('load_data', ['departament', 'factory'])
      const rep_data = this.$store.getters.get_reports_data('base')
      if (rep_data) {
        this.selected = rep_data.selected
        this.selected_date = rep_data.date
      }
    },

    data() {
      return {
        start: null,
        end: null,
        selected_date: {},
        selected: null
      }
    },

    computed: {
      departament_list() {
        return this.$store.getters.departament
      },
      factory_list() {
        return this.$store.getters.factory
      },
      data_to_req() {
        return this.selected || {}
      },
      options() {
        let ret = [{text: this.$t('Select Departament'), value: null, disabled: true}];
        const groupd_departaments = groupBy(this.departament_list, 'factory');
        for (const factory of this.factory_list) {
          ret = ret.concat([
            {value: {factory: factory.id}, html: `<b>${factory.name}</b>`}
          ]);
          ret = ret.concat((groupd_departaments[factory.id] || []).map(
            d => ({value: {departament: d.pk}, text: d.name})
          ))
        }
        return ret
      },
    },
    methods: {
      emit_ok() {
        const obj = merge(this.selected_date, this.selected || {});
        this.$store.commit('set_report_data', {
          key: 'base',
          value: {date: this.selected_date, selected: this.selected}
        });
        this.$emit('ok', obj)
      }
    }
  }
</script>

<style scoped>

</style>
