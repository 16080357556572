const SERVER = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000' : '';
const CSRF_TOKEN_HEADER_KEY = 'X-CSRFToken';

const LOGOUT_TIMEOUT = 60;
const TIMEOUT_DEBOUNCE = 100;


export {
  SERVER, CSRF_TOKEN_HEADER_KEY,
  LOGOUT_TIMEOUT, TIMEOUT_DEBOUNCE
}
