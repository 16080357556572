<template>
  <b-form @submit.prevent="login()" class="login-form">
    <h1>{{ $t('Login') }}</h1>
    <b-form-group :label="$t('Username')" :invalid-feedback="other_errors" :state="other_errors_state">
      <b-form-input v-model="send_data.username" required :state="other_errors_state"/>
    </b-form-group>
    <b-form-group :label="$t('Password')" :invalid-feedback="password_errors">
      <b-form-input type="password" v-model="send_data.password" required/>
    </b-form-group>
    <b-form-group>
      <b-button type='submit' variant='primary'>{{ $t('Login') }}</b-button>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: "LoginForm",
  inject: ['_add_event_listner'],
  data() {
    return {
      send_data: {
        username: "",
        password: "",
      },
      errors: {}
    };
  },
  computed: {
    login_errors() {
      const key = 'username'
      let ret = null
      if (this.errors[key]) {
        ret = this.errors[key].join(', ')
      }
      return ret
    },
    password_errors() {
      const key = 'password'
      let ret = null
      if (this.errors[key]) {
        ret = this.errors[key].join(', ')
      }
      return ret
    },
    other_errors() {
      const key = 'non_field_errors'
      let ret = null
      if (this.errors[key]) {
        ret = this.errors[key].join(', ')
      }
      return ret
    },
    other_errors_state() {
      let ret = null
      if (this.other_errors !== null) {
        ret = this.other_errors.length === 0
      }
      return ret
    }
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.send_data).then(
          ({status, data}) => {
            if (status) {
              this.errors = {}
            } else {
              this.errors = data
            }
            return status
          }
      ).then(st => {
        if (st) {
          return this.$store.dispatch('check_login').then(
              () => {
                this._add_event_listner();
                if (this.$store.getters.user_info.is_need_change) {
                  this.$router.push({name: 'SetPassword'});
                }
              }
          )
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
