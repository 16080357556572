<template>
  <b-form @submit.prevent="emit_ok()">
    <b-form-group :label="$t('Category')">
      <b-form-select
        multiple
        v-model="data_to_send.category"
        :options="categories"
        value-field="pk"
        text-field="name"
      />
    </b-form-group>
    <b-form-group :label="$t('Lines')">
      <b-form-select
        multiple
        v-model="data_to_send.lines"
        :options="lines"
        value-field="pk"
        text-field="name"
      />
    </b-form-group>

    <b-form-group label="">
      <b-radio-group
        v-model="data_to_send.search_in"
        buttons
        button-variant="outline-primary"
        :options="[
          { value: 'deadline', text: $t('Deadline') },
          { value: 'quiz', text: $t('Health Check') },
        ]"
      />
    </b-form-group>
    <b-form-group :label="$t('Date')">
      <date-picker
        v-model="date"
        ref="dt"
        :request="to_link"
        request_url="api/reports/actions_dates"
        :max="null"
      />
    </b-form-group>
    <b-form-group>
      <b-button variant="primary" type="submit">Ok</b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import DatePicker from "./DateTimePicker";

export default {
  name: "ActionsForm",
  components: { DatePicker },
  mounted() {
    this.$store.dispatch("load_data", ["category", "additional_info"]);
  },
  data() {
    return {
      data_to_send: {
        category: [],
        lines: [],
        search_in: "deadline",
      },
      date: { period: "week", start: new Date(), end: null },
    };
  },
  computed: {
    to_link() {
      return { ...this.data_to_send };
    },
    categories() {
      return this.$store.getters["category"].filter((c) => !c.mark_as_deleted);
    },
    lines() {
      return this.$store.getters["line"].filter((l) => !l.deleted);
    },
  },
  methods: {
    emit_ok() {
      this.$emit("ok", { ...this.data_to_send, ...this.date });
    },
  },
};
</script>

<style scoped>
</style>
