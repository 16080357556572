<template>
  <b-form @submit.prevent="emit_ok()">
    <b-form-group :label="$t('Catogory')">
      <b-form-select
        :options="cat_list"
        v-model="category"
        required
        value-field="pk"
        text-field="name"
        :placeholder="$t('Select Departament')"
      />
    </b-form-group>
    <b-form-group :label="$t('Departament')">
      <b-form-select
        :options="options"
        v-model="selected"
        required
        value-field="value"
        :placeholder="$t('Select Departament')"
      />
    </b-form-group>
    <b-form-group :label="$t('Line')">
      <b-form-select
        :options="line_list"
        v-model="line"
        value-field="pk"
        text-field="_name"
      />
    </b-form-group>
    <b-form-group :label="$t('Date')">
      <date-picker
        v-model="selected_date"
        :request="data_to_req"
        :allowed_periods="['week', 'month', 'day', 'period']"
      />
    </b-form-group>
    <b-form-row>
      <b-button variant="primary" type="submit">Ok</b-button>
    </b-form-row>
  </b-form>
</template>
  
  <script>
import { groupBy, merge } from "lodash-es";
import DatePicker from "./DateTimePicker";
const REPORT_TYPE = "catperf";
export default {
  name: "CatPerfForm",
  components: { DatePicker },
  mounted() {
    // restore
    this.$store.dispatch("load_data", [
      "departament",
      "factory",
      "category",
      "additional_info",
    ]);
    const rep_data = this.$store.getters.get_reports_data(REPORT_TYPE);
    if (rep_data) {
      this.selected = rep_data.selected;
      this.selected_date = rep_data.date;
      this.line = rep_data.line;
      this.category = rep_data.category;
    }
    this.$watch(
      () => this.selected?.departament,
      () => (this.line = null)
    );
  },

  data() {
    return {
      start: null,
      end: null,
      selected_date: {},
      line: null,
      selected: null,
      category: null,
    };
  },

  computed: {
    current_dep() {
      return this.departament_list.find(
        (d) => d.pk == this.selected?.departament
      );
    },
    line_list() {
      return [
        { pk: null, disabled: false, _name: this.$t("---- All ----") },
      ].concat(
        this.$store.getters.line.filter(
          (l) => l.departament_uuid == this.current_dep?.uuid && !l.deleted
        )
      );
    },
    cat_list() {
      return [
        { pk: null, name: this.$t("Select Category"), disabled: true },
      ].concat(this.$store.getters.category.filter((c) => !c.mark_as_deleted));
    },
    departament_list() {
      return this.$store.getters.departament;
    },
    factory_list() {
      return this.$store.getters.factory;
    },
    data_to_req() {
      return {
        departament: this.selected?.departament,
        date: this.selected_date,
        category: this.category,
        info: this.line,
      };
    },
    options() {
      let ret = [
        { text: this.$t("Select Departament"), value: null, disabled: true },
      ];
      const groupd_departaments = groupBy(this.departament_list, "factory");
      for (const factory of this.factory_list) {
        //ret = ret.concat([
        //  { value: { factory: factory.id }, html: `<b>${factory.name}</b>` },
        //]);
        ret = ret.concat(
          (groupd_departaments[factory.id] || []).map((d) => ({
            value: { departament: d.pk },
            text: d.name,
          }))
        );
      }
      return ret;
    },
  },
  methods: {
    emit_ok() {
      const obj = merge(this.selected_date, this.selected || {}, {
        category: this.category,
        line: this.line,
      });

      this.$store.commit("set_report_data", {
        key: REPORT_TYPE,
        value: {
          date: this.selected_date,
          selected: this.selected,
          category: this.category,
          line: this.line,
        },
      });
      this.$emit("ok", obj);
    },
  },
};
</script>
  
  <style scoped>
</style>
  