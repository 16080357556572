import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SelectReport from '../views/SelectReport'
import SelectOptions from '../views/SelectOption'
import ChangePasswordView from "../views/ChangePasswordView";
import store from '../store'
import CatPerfReport from '../views/CatPerfReport'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/select/report',
    name: 'Reports',
    component: SelectReport
  },
  {
    path: '/select/options',
    name: 'Options',
    component: SelectOptions
  },
  {
    path: '/catperf',
    name: 'CategoryPerfomance',
    component: CatPerfReport
  },
  {
    path: '/report',
    name: 'BaseReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "base-report" */ '../views/BaseReport.vue')
  },
  {
    path: '/by_name',
    name: 'ReportByPerson',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "report-by-name" */ '../views/ReportByPerson.vue')
  },
  {
    path: '/weekly_report',
    name: 'WeeklyReport',
    component: () => import(/* webpackChunkName: "week-report" */ '../views/WeeklyReport.vue')
  },
  {
    path: '/hcd',
    name: 'HCDReport',
    component: () => import(/* webpackChunkName: "hcd-report" */ '../views/HCDReport.vue')
  },
  {
    path: '/cell',
    name: 'Cells',
    component: () => import(/* webpackChunkName: "cells" */ '../views/DepartamentCells.vue'),
  },
  {
    path: '/line',
    name: 'Lines',
    component: () => import(/* webpackChunkName: "cells" */ '../views/DepartamentCells.vue'),
    props: () => ({
      type: 'line'
    })
  },
  {
    path: '/device',
    name: "Devices",
    component: () => import(/* webpackChunkName: "devices" */ '../views/Devices.vue'),
  },
  {
    path: '/actions',
    name: 'Actions',
    component: () => import(/* webpackChunkName: "actions" */ '../views/ActionsReport.vue'),
  },
  {
    path: '/quiz',
    name: 'Quizes',
    component: () => import(/* webpackChunkName: "quizes" */ '../views/Quizes.vue'),
  },
  {
    path: '/category',
    name: "Category",
    component: () => import(/* webpackChunkName: "cat_list" */ '../views/CategoryList.vue'),
  },
  {
    path: '/graph',
    name: 'Graph',
    component: () => import(/* webpackChunkName: "graph" */ '../views/Graph.vue'),
  },
  {
    path: '/set_password',
    name: 'SetPassword',
    component: ChangePasswordView
  },
  {
    path: '/:type',
    name: 'Info',
    component: () => import(/* webpackChunkName: "persons" */ '../views/PersonsList.vue'),
    props: route => ({ type: route.params.type })
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == 'Home') return next()
  store.commit('toggle_wait', true);
  store.dispatch('check_login').then(
    v => {
      store.commit('toggle_wait', false);
      if (v) {
        if (store.getters.user_info.is_need_reloging) {
          store.dispatch('logout').then(
            () => next({ name: 'Home' })
          )
        }
        return next()
      }
    }
  )
});

export default router
