<template>
  <b-container class="w_100" v-if="$store.getters.has_auth">
    <b-row>
      <b-col>
        <h1>
          {{ $t("Home") }}
          <b-button-group class="logout-btn">
            <b-radio-group
              v-model="lang"
              :options="['en', 'ru']"
              buttons
              button-variant="outline-primary"
            />
            <b-button
              v-if="$store.getters.user_info.username"
              variant="outline"
              :to="{ name: 'SetPassword' }"
              v-b-tooltip="$t('change_password')"
            >
              {{ $store.getters.user_info.username }}
            </b-button>
            <!--<b-button variant="outline" :to="{name: 'SetPassword'}">{{$t('change_password')}}</b-button>-->
            <b-button @click="logout()" variant="danger">{{
              $t("Logout")
            }}</b-button>
          </b-button-group>
        </h1>
        <b-breadcrumb :items="br_items"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="actions">
      <b-col>
        <b-button
          variant="outline-primary"
          class="action"
          :to="{ name: 'Reports' }"
        >
          <b-icon-file-earmark-bar-graph />
          <br />
          {{ $t("Health Check Reports") }}
        </b-button>
      </b-col>
      <b-col v-if="is_superuser">
        <b-button
          variant="outline-secondary"
          class="action"
          :to="{ name: 'Options' }"
        >
          <b-icon-gear />
          <br />
          {{ $t("Settings") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col>
        <login-form />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

import LoginForm from "../components/LoginForm";

export default {
  name: "Home",
  components: { LoginForm },
  props: {
    do_sync: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    console.log("hc:go to home");
  },
  computed: {
    is_superuser() {
      let ret = false;
      if (this.$store.getters.has_auth) {
        ret = this.$store.getters.user_info.is_superuser;
      }
      return ret;
    },
    br_items() {
      return [{ text: this.$t("Home"), active: true }];
    },
    lang: {
      get() {
        return this.$i18n.locale;
      },
      set(to) {
        this.$i18n.locale = to;
        this.$store.commit("update_lang", to);
      },
    },
  },
  methods: {
    logout() {
      this.$bvModal
        .msgBoxConfirm(this.$t("Are you sure?"), {
          okTitle: this.$t("Ok"),
          cancelTitle: this.$t("Cancel"),
        })
        .then((val) => {
          if (val) {
            this.$store.dispatch("logout");
          }
        });
    },
  },
};
</script>

<style lang="scss">
.login-form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.logout-btn {
  float: right;
  margin-top: 0.25em;
}

.actions {
  .col {
    margin-top: 1em;
  }

  .action {
    font-size: 1.4em;
    padding: 1em;
    height: 100%;
    width: 100%;

    .b-icon.bi {
      font-size: 5em;
      margin-bottom: 1rem;
    }
  }
}
</style>
