<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Health Check Reports") }}
          <b-link :to="{ name: 'Home' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="actions">
      <b-col v-for="(item, key) in reports_types" :key="key">
        <b-button
          :variant="item.variant"
          class="action"
          @click="set_report_type(key)"
        >
          <b-icon-file-earmark-bar-graph />
          <br />
          {{ $t(item.text) }}
        </b-button>
      </b-col>
      <b-col>
        <b-button
          variant="outline-secondary"
          class="action"
          :to="{ name: 'Quizes' }"
        >
          <b-icon-question />
          <br />
          {{ $t("Health Checks") }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal :title="report_header" v-model="show_report_modal" hide-footer>
      <comp v-if="report_type" :is="form_comp" @ok="go_to_report" />
    </b-modal>
  </b-container>
</template>

<script>
// @ is an alias to /src
import ReportForm from "../components/ReportForm";
import { merge, pickBy } from "lodash-es";
import WeeklyReportForm from "../components/WeeklyReportForm";
import HCDReportVue from "../components/HCDReport.vue";
import ReportByPersonForm from "../components/ReportByPersonForm";
import ActionsForm from "../components/ActionsForm";
import GraphForm from "../components/GraphForm";
import { date_format } from "@/shared";
import CatPerfFormVue from "../components/CatPerfForm.vue";

const format_date = date_format;

const REPORTS = {
  report: {
    form: ReportForm,
    path: "BaseReport",
    text: "Main HC Report",
    variant: "outline-primary",
  },
  weekly: {
    form: WeeklyReportForm,
    path: "WeeklyReport",
    text: "Weekly Report",
    variant: "outline-success",
  },
  hcd: {
    form: HCDReportVue,
    path: "HCDReport",
    text: "Detailed Report",
    variant: "outline-warning",
  },
  by_person: {
    form: ReportByPersonForm,
    path: "ReportByPerson",
    text: "Report by Person",
    variant: "outline-secondary",
  },
  actions: {
    form: ActionsForm,
    path: "Actions",
    text: "Action Items Report",
    variant: "outline-info",
  },
  category_perfomance: {
    form: CatPerfFormVue,
    path: "CategoryPerfomance",
    text: "Category Perfomance",
    variant: "outline-danger",
  },
  graph: {
    form: GraphForm,
    path: "Graph",
    text: "Chart Report",
    variant: "info",
  },
};
const REPORTS_FORMS = Object.entries(REPORTS).reduce(
  (r, [key, value]) => merge(r, { [key]: value.form }),
  {}
);
const REPORTS_PATH = Object.entries(REPORTS).reduce(
  (r, [key, value]) => merge(r, { [key]: value.path }),
  {}
);

export default {
  name: "Home",
  components: { ReportForm },
  data() {
    return {
      show_report_modal: false,
      report_type: null,
      report_header: "",
    };
  },
  computed: {
    reports_types: () => REPORTS,
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Reports"), active: true },
      ];
    },
    form_comp() {
      return this.report_type ? REPORTS_FORMS[this.report_type] : null;
    },
  },
  methods: {
    set_report_type(type) {
      this.report_type = type;
      this.show_report_modal = true;
      this.report_header = this.$t(REPORTS[type].text);
    },
    go_to_report(event) {
      console.log("hc:go_to", event);
      this.show_report_modal = false;
      let q = merge({}, event);
      if (q.start) {
        q.start = format_date(q.start);
      }
      if (q.end) {
        q.end = format_date(q.end);
      }

      console.log("hc:go to ", q, event);
      this.$router.push({
        name: REPORTS_PATH[this.report_type] || "Home",
        query: pickBy(q, (v) => v !== null),
      });
    },
  },
};
</script>

<style lang="scss">
.actions {
  gap: 1rem;

  .action {
    font-size: 1.4em;
    padding: 1em;
    height: 100%;
    width: 100%;

    .b-icon.bi {
      font-size: 5em;
      margin-bottom: 1rem;
    }
  }
}
</style>
